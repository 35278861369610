import { Iterable } from 'immutable';

// Function to check if a value is serializable
const isSerializable = (value) =>
  Iterable.isIterable(value) || typeof value === 'object';

// Function to serialize a non-serializable value
const serializeValue = (value) => {
  if (Iterable.isIterable(value)) {
    // Serialize Immutable.js iterables to plain JavaScript objects
    return value.toJS();
  }

  if (typeof value === 'object') {
    // Serialize plain JavaScript objects to JSON strings
    return JSON.stringify(value);
  }

  // For other types, return as is
  return value;
};

// Create custom middleware
const serializationMiddleware = () => (next) => (action) => {
  const { payload } = action; // Get the payload from the action

  if (payload) {
    const { nonSerializablePayload } = payload;

    if (nonSerializablePayload && !isSerializable(nonSerializablePayload)) {
      // Serialize the non-serializable payload
      payload.nonSerializablePayload = serializeValue(nonSerializablePayload);
    }
  }

  return next(action);
};

export default serializationMiddleware;
