import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import  { setCredentials, logout } from "../context/auth/authSlice";

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL; 

const baseQuery = fetchBaseQuery({
    baseUrl : API_URL,
    credentials : 'include',
    prepareHeaders : (headers, {getState}) =>{
        const token = getState().auth.accessToken;
        if(token){
            headers.set("Authorization",`Bearer ${token}`);
        }
        return headers
    }
});


const baseQueryWithReAuth = async (args,api,extraOptions)=>{
    let result = await baseQuery(args,api,extraOptions);
    if( result?.error?.originalStatus === 403 || 
        result?.error?.originalStatus === 401 || 
        result?.error?.status === 401 
        || result?.error?.status === 403
        ){
        // sending the refresh token to get access authorizations 
        const refreshResult = await baseQuery('/refresh',api, extraOptions);
        if(refreshResult?.data){
            api.dispatch(setCredentials({...refreshResult.data}));
            // retry the original query with new token
            result = await baseQuery (args,api,extraOptions);
        }else{
            /*eslint-disable */
            api.dispatch(logout());
        }
    }
    return result;
}
 /* eslint-disable */
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery : baseQueryWithReAuth,
    tagTypes: ['User', 'Profile', 'Product', 'Category', 'Store', 'Province','Order','Purchase','Sale','Customer','Supplier','Job', 'Brand', 'Config', 'Notification', 'Chart', 'Review'],
     /* eslint-disable */
    endpoints : builder =>({})
})
