import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import serializeMiddleware from 'store/customMiddleware';
import authReduder  from "context/auth/authSlice";
import { apiSlice } from "api/apiSlice";



 /* eslint-disable */
export const store = configureStore({
    reducer : {
        [apiSlice.reducerPath] : apiSlice.reducer,
        auth   : authReduder,
    },
    middleware : getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                // ignoredActionPaths: ['meta.baseQueryMeta.request','meta.baseQueryMeta.response'],
                ignoredPaths: [],
            }
        }).concat([
            apiSlice.middleware,
            serializeMiddleware
        ]),
    
    devTools : true
    
})
setupListeners(store.dispatch)