/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/
import React, { useRef, useEffect,  createContext, useContext, useReducer, useMemo } from "react";
import { useGetUserConfigQuery, useAddNewConfigMutation } from "context/config/configApiSlice";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIcontext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value.config, miniDrawerEdit : action.value.edit || "config", widthConfigurator :  action.value.width || 360, id: action.value.id  };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "SEARCH_TERM": {
      return { ...state, searchTerm: action.value };
    }
    case "SHOPING_CART": {
      return { ...state, cart: action.value };
    }
    case "CHAT_BOX": {
      return { ...state, chatBox : action.value };
    }
    case "LOAD_ALL": {
      return { ...state, ...action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {

  const initialState = {
    miniSidenav: false,
    miniDrawerEdit : "config",
    transparentNavbar: true,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    fixedNavbar: true,
    darkMode: false,
    direction: "ltr",
    openConfigurator: false,
    widthConfigurator:360,
    id : null,
    isLoading : false,
    layout: "dashboard",
    searchTerm : {
      text:'', 
      filters:{name:'', province:'', category:'',price:'', color:'', store:''},
      message:{filters:{content:'',search:""}, page:0, sortBy:'dateASC', entriesPerpage : 25},
      sortBy:'dateASC', 
      page:0, 
      entriesPerpage : 10
    },
    cart : JSON.parse(localStorage.getItem('shoping-cart')) || {items:[], quantity : 0},
    chatBox : null
  };

  const updateThemePermit = useRef(false);
  const [addNewConfig] = useAddNewConfigMutation();

  const {
    data: config = undefined,
      isSuccess,
  } = useGetUserConfigQuery({configType:"theme", active : 1}, {
      refetchOnMountOrArgChange: true,
      skip: updateThemePermit.current
  });

  const [controller, dispatch] = useReducer(reducer, initialState);

  let mounted;
  /* eslint-disable */
  useEffect( async () => {
    mounted = true;
    if( isSuccess && mounted ){
      config && setLoadAll(dispatch, config.configData);
      if(!config) await addNewConfig();
    }
    return () => mounted = false;
  }, [isSuccess])

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setSearchTerm = (dispatch, value) => dispatch({ type: "SEARCH_TERM", value });
const setShopingCart = (dispatch, value) => dispatch({ type: "SHOPING_CART", value });
const setLoadAll = (dispatch, value) => dispatch({ type: "LOAD_ALL", value });
const setChatBox = (dispatch, value) => dispatch({ type: "CHAT_BOX", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setSearchTerm,
  setShopingCart,
  setChatBox
};
