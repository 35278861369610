/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "store";

import SplashScreen  from "layouts/splash-screens";
import { MaterialUIControllerProvider } from "context";
import { injectStore } from "api/axiosPrivate";

injectStore(store);

const App = lazy(() => import('App'));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <Suspense fallback={<SplashScreen/>}>
              <Routes>
                <Route path="/*" element={<App/>}/>
              </Routes>
            </Suspense>
        </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
