import React from 'react'

// import store from "assets/loading-screen/3dStore.gif";
// import cart from "assets/loading-screen/cart2.gif";
import bus from "assets/images/shapes/stockApp.gif";

import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/styles";

const index = () => {
  const background =  bus;
  /* masking splash-screen */
  /* eslint-disable */
  const useStyles = makeStyles((theme) => ({
  backgroundMask : {
    height: '100vh',
    width : '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:"#eaeaea",
    overflow : "hidden"
  },

  overlayMask : {
      backgroundColor: 'transparent',
      transition: 'all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      height: "400px",
      width:"400px",
      '&hover': {
        backgroundColor: '#ffffffee',
        mixBlendMode: 'lighten', /* the overlay is painted only in areas where it is lighter than its parent (the background) */
    }
  },

  overlayTextMask :{
      fontSize: '1.2rem',
      color: 'white',
      display: 'inline-block',
      position: 'relative'
  },

  overlayShapeMask :{
      fill: 'blue'
  },
  image : {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: -1,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  'typewriterh1' : {
    overflow: 'hidden', /* Ensures the content is not revealed until the animation */
    whiteSpace: 'nowrap', /* Keeps the content on a single line */
    margin:' 0 auto', /* Gives that scrolling effect as the typing happens */
    letterSpacing: '.15em', /* Adjust as needed */
    animation: '$typing 3.5s infinite',
  },
  
  /* The typing effect */
  '@keyframes typing': {
    "0%" : { width: 0 },
    "100%" : { width: '100%' }
  },
  
  }));
  const classes = useStyles();
  return (
    <>
    <CssBaseline />
    <section className={classes.backgroundMask}>
    <div className={classes.overlayMask}>
    <figure style={{margin:0}}>
        <svg id="sw-js-blob-svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <mask id="mask" className={classes.overlayShapeMask}>
                    <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                        <stop id="stop1" stopColor="rgba(248, 117, 55, 1)" offset="0%"/>
                        <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"/>
                    </linearGradient>
                    <path  fill="url(#sw-gradient)" d="M25.5,-30.9C30.6,-26.1,30.7,-15.7,30.2,-7C29.8,1.7,28.8,8.9,26,16.1C23.1,23.3,18.4,30.4,11.1,35C3.8,39.6,-6.1,41.7,-14.4,38.9C-22.7,36.2,-29.5,28.5,-34.7,19.8C-40,11.2,-43.8,1.4,-43,-8.4C-42.2,-18.1,-36.8,-27.8,-28.9,-32.1C-21,-36.4,-10.5,-35.3,-0.2,-35.1C10.2,-34.9,20.4,-35.6,25.5,-30.9Z" width="100%" height="100%" transform="translate(55 48) scale(1,.95)" strokeWidth="0" style={{transition: 'all 0.3s ease 0s'}} stroke="url(#sw-gradient)"/>
                </mask>
            </defs>
            <image className={classes.image} mask="url(#mask)"  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={background}  />
        </svg>
        <figcaption  style={{textAlign:'center'}}><h1 className={classes.typewriterh1} style={{marginTop:-30}}>Loading...</h1></figcaption>
    </figure>
    </div>
    </section>
    </>
  )
}

export default index;