import { apiSlice } from "api/apiSlice";
import {
    createSelector,
    createEntityAdapter,
} from "@reduxjs/toolkit";

const configAdapter = createEntityAdapter({})

const initialState = configAdapter.getInitialState({
    configsDetails : {
        page: 0,
        filters: {},
        configsPerpage: 20,
        total_results: 0
    },
})

export const configApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConfig: builder.query({
            query: params => ({ url: `configs/`, params }),
            validateStatus: (response, result) => response.status === 200 && !result.isError,
            transformResponse: responseData => {
                const loadedConfig = responseData.configs.map(config => {
                    const newConfig = config;
                    // eslint-disable-next-line
                    if(loadedConfig?.configData?.createdAt)  {
                        const date = new Date(loadedConfig.configData.createdAt);
                        newConfig.configData.createdAt = date;
                    }
                    return newConfig;
                });

                return configAdapter.setAll(initialState, loadedConfig)     
            },
            /* eslint-disable */
            providesTags: (result, error, id) => // [{ type: 'Config', arg }]
             {
                /* eslint-disable */
                if (result?.ids) {
                    return [
                        { type: 'Config', id: 'PARTIAL-LIST' },
                        ...result.ids.map(id => ({ type: 'Config', id }))
                    ]
                } else return [{ type: 'Config', id: 'PARTIAL-LIST' }]
            }
        }),
        getAutoCompleteConfigs: builder.query({
            query: params => ({ url: `configs/`, params }),
            validateStatus: (response, result) => response.status === 200 && !result.isError,
            transformResponse: responseData => {
                const loadedConfig = responseData.configs.map(config => {
                    const newConfig = {}
                    newConfig.id = config.id;
                    newConfig.name = config.configName;
                    newConfig.active = config.active;
                    return newConfig;
                });

                return loadedConfig;
            },
        }),
        getUserConfig: builder.query({
            query: params => ({ url: `/userConfig`, params }),
            validateStatus: (response, result) => response.status === 200 && !result.isError,
            transformResponse: responseData => {
                const loadedConfig = responseData;
                // eslint-disable-next-line
                if(loadedConfig?.configData?.createdAt){
                    const date = new Date(loadedConfig.configData.createdAt);
                    loadedConfig.configData.createdAt = date;
                }
                return loadedConfig
            },
            /* eslint-disable */
            providesTags: (result, error, id) => [{ type: 'Config', id }],
        }),
        getConfigById: builder.query({
            query: id => ({ url: `/configs/${id}` }),
            validateStatus: (response, result) => response.status === 200 && !result.isError,
            transformResponse: responseData => {
                // eslint-disable-next-line
                if(responseData?.configData?.createdAt)  {
                    const date = new Date(loadedConfig.configData.createdAt);
                    responseData.configData.createdAt = date;
                }
                return responseData
            },
            /* eslint-disable */
            providesTags: (result, error, id) => [{ type: 'Config', id }],
        }),
        addNewConfig: builder.mutation({
            query: initialConfigData => ({
                url: '/configs',
                method: 'POST',
                body: {
                    ...initialConfigData,
                }
            }),
            invalidatesTags: [
                { type: 'Config', id: "LIST" }
            ]
        }),
        updateConfig: builder.mutation({
            query: initialConfigData => ({
                url: '/configs',
                method: 'PATCH',
                body: {
                    ...initialConfigData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Config', id: arg.id }
            ]
        }),
        updateColors: builder.mutation({
            query: initialConfigData => ({
                url: '/configs',
                method: 'PUT',
                body: {
                    ...initialConfigData,
                }
            }),
        }),
        deleteConfig: builder.mutation({
            query: ({ id }) => ({
                url: `/configs`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Config', id: arg.id },
                { type: 'Config', id: 'PARTIAL-LIST' }
            ]
        }),
    }),
})

export const {
    useGetConfigQuery,
    useLazyGetConfigQuery,
    useLazyGetAutoCompleteConfigsQuery,
    useGetUserConfigQuery,
    useLazyGetUserConfigQuery,
    useLazyGetConfigByIdQuery,
    useAddNewConfigMutation,
    useUpdateConfigMutation,
    useUpdateColorsMutation,
    useDeleteConfigMutation,
} = configApiSlice

// returns the query result object
export const selectConfigResult = configApiSlice.endpoints.getConfig.select()

// creates memoized selector
const selectConfigData = createSelector(
    selectConfigResult,
    configResult => configResult.data // normalized state object with ids & entities
)
//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllConfigs,
    selectById: selectConfigById,
    selectIds: selectConfigIds
    // Pass in a selector that returns the config slice of state
} = configAdapter.getSelectors(state => selectConfigData(state) ?? initialState)

export const selectConfigByUser = createSelector(
    [selectAllConfigs,(state, userId) => userId],
    (configs, userId) => configs.find(config => config.userId === userId)
);
